import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { baseQuery } from './common';
import { IQueue } from '../../interfaces/queue';

export const queueApi = createApi({
	reducerPath: 'queue',
	baseQuery: baseQuery,
	tagTypes: ['Queue'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getQMS: builder.query<IApiResponse<IQueue>, { departmentId: string; pagination: { page: number; limit: number } }>({
			query: ({ departmentId, pagination: { page = 1, limit = 10 } }) =>
				`/qms/queue/by-department/${departmentId}?page=${page}&limit=${limit}`,
			providesTags: ['Queue'],
		}),
		addQueue: builder.mutation<IQueue, Partial<IQueue>>({
			query: (body) => ({
				url: `queue`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Queue'],
		}),
	}),
});

export const { useGetQMSQuery, useAddQueueMutation } = queueApi;
